import Login from '@/pages/auth/login/Loadable'
import SignUp from '@/pages/auth/signup/Loadable'
import ResetOtp from '@/pages/auth/resetOtp/Loadable'
import AuthOtp from '@/pages/auth/otp/Loadable'
import Password from '@/pages/auth/password/Loadable'
import DepartmentUsers from '@/pages/auth/departmentUsers/Loadable'
import UserLog from '@/pages/history/userLog/Loadable'
import DownloadLog from '@/pages/history/downloadLog/Loadable'
import DeliveryAreas from '@/pages/master/deliveryAreas'
import InterfaceHistory from '@/pages/history/interfaceHistory'

export const ROUTES_WITH_ELEMENTS = {
  // Authorization
  auth: {
    // 로그인 이전
    login: <Login />,
    signup: <SignUp />,
    'reset-otp': <ResetOtp />,
    // 로그인 이후
    otp: <AuthOtp />,
    password: <Password />,
    'department-users': <DepartmentUsers />,
  },
  // Master
  ma: {
    // ma01: <InlineFramePage />,
    // ma02: <InlineFramePage />,
    // ma03: <InlineFramePage />,
    // ma04: <InlineFramePage />,
    // ma05: <InlineFramePage />,
    // sy07: <InlineFramePage />,
    // // ma08: <InlineFramePage />, 미사용
    // ma09: <InlineFramePage />,
    // ma10: <InlineFramePage />,
    // ma11: <InlineFramePage />,
    // ma12: <InlineFramePage />,
    ma13: <DeliveryAreas />,
  },
  // gr: {
  //   gr01: <InlineFramePage />,
  //   gr02: <InlineFramePage />,
  //   gr03: <InlineFramePage />,
  //   gr04: <InlineFramePage />,
  //   gr05: <InlineFramePage />,
  //   gr06: <InlineFramePage />,
  //   egr01: <InlineFramePage />,
  // },
  // rgr: {
  //   rgr01: <InlineFramePage />,
  //   rgr02: <InlineFramePage />,
  //   rgr03: <InlineFramePage />,
  //   rgr04: <InlineFramePage />,
  //   rgr05: <InlineFramePage />,
  //   rgr06: <InlineFramePage />,
  //   rgr07: <InlineFramePage />,
  //   rgr08: <InlineFramePage />,
  //   rgr09: <InlineFramePage />,
  //   rgr10: <InlineFramePage />,
  //   rgr11: <InlineFramePage />,
  // },
  // gi: {
  //   gi01: <InlineFramePage />,
  //   gi02: <InlineFramePage />,
  //   gi03: <InlineFramePage />,
  //   gi04: <InlineFramePage />,
  //   gi05: <InlineFramePage />,
  //   gi06: <InlineFramePage />,
  //   gi07: <InlineFramePage />,
  //   gi08: <InlineFramePage />,
  //   gi11: <InlineFramePage />,
  //   gi12: <InlineFramePage />,
  //   gi13: <InlineFramePage />,
  //   gi14: <InlineFramePage />,
  //   gi15: <InlineFramePage />,
  //   gi16: <InlineFramePage />,
  //   gi17: <InlineFramePage />,
  //   gi19: <InlineFramePage />,
  //   gi20: <InlineFramePage />,
  //   gi22: <InlineFramePage />,
  //   ts01: <InlineFramePage />,
  // },
  // st: {
  //   st01: <InlineFramePage />,
  //   st02: <InlineFramePage />,
  //   st03: <InlineFramePage />,
  //   st04: <InlineFramePage />,
  //   st05: <InlineFramePage />,
  //   st07: <InlineFramePage />,
  //   est01: <InlineFramePage />,
  //   est02: <InlineFramePage />,
  //   est03: <InlineFramePage />,
  // },
  // bl: {
  //   rp03: <InlineFramePage />,
  //   rp04: <InlineFramePage />,
  //   rp05: <InlineFramePage />,
  //   bl01: <InlineFramePage />,
  // },
  // rp: {
  //   rp01: <InlineFramePage />,
  //   rp02: <InlineFramePage />,
  //   rp07: <InlineFramePage />,
  //   rp09: <InlineFramePage />,
  //   ts05: <InlineFramePage />,
  //   erp09: <InlineFramePage />,
  //   rp10: <InlineFramePage />,
  //   rp11: <InlineFramePage />,
  // },
  // sy: {
  //   sy01: <InlineFramePage />,
  //   sy02: <InlineFramePage />,
  //   sy03: <InlineFramePage />,
  //   sy04: <InlineFramePage />,
  //   sy05: <InlineFramePage />,
  //   sy06: <InlineFramePage />,
  //   sy08: <InlineFramePage />,
  //   sy09: <InlineFramePage />,
  // },
  // 이관 완료
  hs: {
    hs01: <UserLog />, // 사용자 로그
    // hs02 - 관리자로그 [ 미사용 메뉴 ]
    hs03: <DownloadLog />, // 다운로드로그
    // hs04 - ERP 자료업로드 [ 미사용 메뉴 ]
    rp06: <InterfaceHistory />, // I/F History
  },
  // tm: {
  //   tm01: <InlineFramePage />,
  //   tm02: <InlineFramePage />,
  //   tm03: <InlineFramePage />,
  // },
  // gl: {
  //   gl01: <InlineFramePage />,
  //   gl02: <InlineFramePage />,
  //   gl03: <InlineFramePage />,
  //   gl04: <InlineFramePage />,
  //   gl05: <InlineFramePage />,
  //   gl06: <InlineFramePage />,
  //   gl07: <InlineFramePage />,
  //   gl08: <InlineFramePage />,
  //   gl09: <InlineFramePage />,
  //   gl10: <InlineFramePage />,
  //   gl11: <InlineFramePage />,
  //   gl12: <InlineFramePage />,
  // },
} as const
