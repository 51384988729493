import qs, { ParsedQs } from 'qs'
import { defaultTo, isArray, isEmpty, isNil, omitBy, toNumber } from 'lodash-es'

export class Utils {
  static isProd() {
    return window.location.hostname === 'moms.musinsalogistics.co.kr'
  }

  static uuid() {
    return '_' + Math.random().toString(36).substring(2, 11)
  }

  static assetUrl(assetPath: string) {
    const pathname = assetPath[0] === '/' ? assetPath.slice(1) : assetPath
    return `${process.env.PUBLIC_URL}/${pathname}`
  }

  static getApiErrMessage(e: unknown, defaultMessage: string) {
    // @ts-ignore
    const message = e?.response?.data?.meta?.message || e.response?.data?.detail || e.response?.data?.message
    return (Array.isArray(message) ? message.join('\n') : message || defaultMessage) + '<span style="color:red"> *</span>'
  }

  static checkContinuousText(text: string, count: number) {
    if (!text) return false

    let continuousCount = 0
    text.split('').some((_, idx) => {
      // break
      if (continuousCount >= count - 1) return true

      // continue
      if (idx > 0 && text[idx] !== text[idx - 1]) {
        continuousCount = 1
      } else if (idx > 0) {
        continuousCount += 1
      }

      return false
    })

    return continuousCount >= count
  }

  static qsParse = <T = ParsedQs>(search: string) => {
    return (search ? qs.parse(search.slice(1), { comma: true, parseArrays: true }) : {}) as T
  }

  static qsStringify = <T = Record<string, never>>(params?: T) => {
    const omittedParams = omitBy(params || {}, (val) => {
      if (isNil(val)) return true
      if (typeof val === 'string' && !val) return true
      return isArray(val) && (val as string[]).every((v) => !v) // array인 경우 모두 빈값이면 안됨.
    })

    return isEmpty(omittedParams) ? '' : `?${qs.stringify(omittedParams, { arrayFormat: 'comma', encode: false })}`
  }

  static getCommaNum = (v?: string | number) =>
    !isNil(v)
      ? defaultTo(toNumber(v), 0)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : ''

  static chunkArray<T>(array: T[], size: number): T[][] {
    const result: T[][] = []
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size))
    }
    return result
  }
}
