import React, { CSSProperties, forwardRef, PropsWithChildren } from 'react'
import styled, { RuleSet } from 'styled-components'

interface StackProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string
  className?: string
  block?: boolean
  full?: boolean
  align?: CSSProperties['alignItems']
  justify?: CSSProperties['justifyContent']
  wrap?: CSSProperties['flexWrap']
  gap?: number
  p?: number | string // padding
  m?: number | string // margin
  bg?: string
  style?: CSSProperties
  onClick?: () => void
  role?: string
}

export const HStack = forwardRef<HTMLDivElement, PropsWithChildren<StackProps>>(({ children, onClick, block, ...rest }, ref) => {
  return (
    <StackWrap {...rest} type="h" ref={ref} $block={block} onClick={onClick}>
      {children}
    </StackWrap>
  )
})

export const VStack = forwardRef<HTMLDivElement, PropsWithChildren<StackProps>>(({ children, onClick, block, full, ...rest }, ref) => {
  return (
    <StackWrap {...rest} type="v" ref={ref} $block={block} $full={full} onClick={onClick}>
      {children}
    </StackWrap>
  )
})

const StackWrap = styled.div<Omit<StackProps, 'block'> & { type: 'v' | 'h'; $block?: boolean; $full?: boolean }>`
  display: flex;
  gap: ${({ gap }) => gap || 0}px;
  flex-direction: ${({ type }) => (type === 'v' ? 'column' : 'row')};

  ${({ type, align, justify, p, m, wrap, bg, $block, $full }) => {
    const styles: RuleSet = []
    if (align) styles.push(`align-items: ${align};`)
    if (justify) styles.push(`justify-content: ${justify};`)
    if (wrap) styles.push(`flex-wrap: ${wrap};`)
    if (bg) styles.push(`background-color: ${bg};`)
    if ($block) styles.push(`width: 100%;`)
    if ($full && type === 'v') styles.push(`height: 100%;`)

    if (p) {
      if (typeof p === 'number') styles.push(`padding: ${p}px;`)
      else if (typeof p === 'string') styles.push(`padding: ${p};`)
    }

    if (m) {
      if (typeof m === 'number') styles.push(`margin: ${m}px;`)
      else if (typeof m === 'string') styles.push(`margin: ${m};`)
    }

    return styles
  }}
`
